<template>
    <div>
        <a-row :gutter="8">
            <a-col :span="24">
              <a-card title="漏损分析" :bordered="false" style="">
                <div class="table-page-search-wrapper">
                  <a-form :form="form" layout="inline">
                    <a-row :gutter="[24, 0]">
                      <a-col :xxl="8" :sm="24">
                        <a-form-item label="选择水厂：">
                          <a-cascader
                                  v-if="cascaderDefault.length > 0"
                            :fieldNames="{ label: 'name', value: 'id', children: 'children' }"
                            :options="tree"
                            placeholder=""
                            :allowClear="false"
                            :defaultValue="cascaderDefault"
                            @change="onCascaderChange"
                          />
                        </a-form-item>
                      </a-col>
                      <a-col :xxl="8" :sm="24">
                        <a-form-item no-prop label="选择日期">
                          <a-range-picker v-model="rangeH" :show-time="{ format: 'HH' }" format="YYYY-MM-DD HH:00"/>

                        </a-form-item>
                      </a-col>

                      <a-col :xxl="8" :sm="24">
                        <span
                          class="table-page-search-submitButtons"
                        >
                          <a-button type="primary" @click="handleQuery">查询</a-button>
                          <!--<a-button style="margin-left: 8px" @click="resetQuery">重置</a-button>-->
                        </span>
                      </a-col>
                    </a-row>
                  </a-form>
                </div>
              </a-card>
                <a-card :body-style="{padding: '5px 5px'}">

                    <div style="height: calc(100vh - 315px);">

                          <my-map  ref="myMap" v-if="loading" :adapter="adapter" :zoom="13.4" :center="convert([ 105.292515, 37.430750 ])" height="100%" projection="EPSG:3857">
                            <my-map-drawer width="300px" height="100px" placement="left" collapsed close-on-click-outside >
                              <my-map-layers :layers="layers" :zIndex="1"></my-map-layers>
                            </my-map-drawer>                             <!--<my-map-placement placement="left-top"  :margin="[100,0,0,10]">
                               <a-radio-group  v-model="showArea" button-style="solid"  @change="showAreaChange">
                                  <a-radio-button value="a">
                                    显示地名
                                  </a-radio-button>
                                  <a-radio-button value="b">
                                    不显示地名
                                  </a-radio-button>
                                </a-radio-group>
                            </my-map-placement>-->
                            <my-map-pointer placement="right-bottom" projection="EPSG:4326"></my-map-pointer>
                            <my-map-zoom theme="light" placement="right-top"></my-map-zoom>
                            <my-map-geo v-if="geoShow" :json="geo"  :zIndex="2"  @ready="geoReadyHandle"></my-map-geo>
                            <!--
                            <my-map-popup width="200px" v-if="showGeoInfo" title="信息" :position="convert([ 105.282302, 37.431180 ])" :showArrow="false">
                              {{region}}
                            </my-map-popup>-->
                            <my-map-scale ></my-map-scale>
                            <my-map-overview :collapsed="true"></my-map-overview>
                            <my-map-marker :src="markerPic" :zIndex="3" v-if="markers" :data="markers" multiple :scale="0.22">
                            </my-map-marker>
                            <my-map-marker  :src="pin" :zIndex="3" v-if="zuitaimarkers" :data="zuitaimarkers" multiple>
                            </my-map-marker>
                            <div v-if="geoShow" v-for="(item) in markers" :key="item.id">
                              <my-map-html :positioning="positioning" :position="item.coordinate">
                                <div style="background-color: rgba(45,140,240,0);color: #22BCB9;font-weight:bold;margin-top: 15px;padding:5px;border-radius:8px;text-align:center;width:auto;">
                                  {{item.dataName}}
                                </div>
                              </my-map-html>
                            </div>
                            <div v-if="geoShow" v-for="(item) in leakListData" :key="item.id">

                              <my-map-popup :position="item.coordinate" :closable="false" :style="{'backgroundColor': colorData[item.color],'color':colorFontData[item.color]}" style="border-radius:8px;text-align:center;width:auto;"  :showArrow="false">
                                <div v-html="item.dataName"></div>
                              </my-map-popup>
                            </div>
                          </my-map>
                    </div>
                </a-card>
            </a-col>
        </a-row>
    </div>
</template>


<script>
  import { fromLonLat } from "ol/proj";
  import Point from 'ol/geom/Point';
  import LineString from 'ol/geom/LineString';
  import Polygon from 'ol/geom/Polygon';
  import LinearRing from 'ol/geom/LinearRing';
  import MultiLineString from 'ol/geom/MultiLineString';
  import {Icon, Stroke, Style,Circle,Fill} from 'ol/style';
  import {Draw, Modify, Snap} from 'ol/interaction';
  import VectorLayer from 'ol/layer/Vector';
  import VectorSource from 'ol/source/Vector';
  import MyMapPointer from '@/components/map/MyMapPointers'
  import {MyMap,MyMapGeo,MyMapLayers,MyMapPlacement,MyMapScale,MyMapOverview,MyMapZoom,MyMapScatter,MyMapLink,MyMapPopup,MyMapMarker,MyMapHtml,MyMapDrawer} from '$ui/map'
  import Image from '@/assets/Image.png'
  import Terrain from '@/assets/terrain.png'
  import TDT from '$ui/map/sources/preview/TDT.png'
  import tianjin from '$ui/charts/geo/province/tianjin.json'
  import henan from '@/assets/henan5.json'
  import TileLayer from 'ol/layer/Tile'
  import XYZ from 'ol/source/XYZ'
  import pin from '$ui/map/sources/pin/red.png'
  import parseStyle from '$ui/map/utils/style'
  import markerPic from '@/assets/marker.png'
  import arrowPng from '$ui/map/sources/arrows/arrow.svg'
  import moment from 'moment'

  import { devicePage,deviceTypeAll,zhibiaoAll,monitorAll,groupAll,waterfactoryAll, findByWfid,waterfactoryTree,getAreaParam,leakAnalysis } from "@/api/monitor/index";

  const setStyle = function (bg = 'rgba(0,0,0,0)',text) {
    return parseStyle({
      stroke: {width: 10, color: bg},
      text: {text: text, font: '16px', fill: '#fff',backgroundFill: 'rgba(45,140,240,0.8)',
          padding: [10, 10, 5, 10],}
    })
  }

  const lsList=["1","2","3","4","5"]
  /**
   * 内部箭头样式
   * 用于每次渲染集合体的时候，OL 均会调用此函数以获得自定义样式
   * @param feature
   * @param res
   * @returns {undefined[]}
   */
  const arrowStyles =  ((feature, res)=> {
      const featureData = feature.getProperties()
      let geometry = feature.getGeometry().getLineString();
      const ls = featureData.ls
      /*let newData = this.leakData.filter(item => item.fromid==featureData.MidFrom&&item.toid==featureData.MidTo)
      let colorIndex=Math.floor(newData[0].leakPercent/5)
      let color=colorData[colorIndex>4?4:colorIndex]*/

      //const value = Math.ceil(225 * parseInt(ls) / 5)
      let styles =  [];

      let color='green'
      if(ls=='0'){
        color=`#0000ff`
        styles =  [
            new Style({
                stroke: new Stroke({
                    color: color,
                    width: 10,
                })
            })
        ];
        return styles;
      }else{
        color=feature.get('color')?feature.get('color'):`#ff9900`;
        styles =  [
            new Style({
                stroke: new Stroke({
                    color: color,
                    width: 10,
                })
            })
        ];
      }
      const length = geometry.getLength();

      var radio = (50 * res) / length;
      var dradio = 1;//投影坐标系，如3857等，在EPSG:4326下可以设置dradio=10000
      for (var i = 0; i <= 1; i += radio) {
        var arrowLocation = geometry.getCoordinateAt(i);
        geometry.forEachSegment(function (end,start ) {
          if (start[0] == end[0] || start[1] == end[1]) return;
          var dx1 = end[0] - arrowLocation[0];
          var dy1 = end[1] - arrowLocation[1];
          var dx2 = arrowLocation[0] - start[0];
          var dy2 = arrowLocation[1] - start[1];
          if (dx1 != dx2 && dy1 != dy2) {
            if (Math.abs(dradio * dx1 * dy2 - dradio * dx2 * dy1) < 0.001) {
                var dx = end[0] - start[0];
                var dy = end[1] - start[1];
                var rotation = Math.atan2(dy, dx);
                styles.push(new Style({
                  geometry: new Point(arrowLocation),
                  image: new Icon({
                    src: arrowPng,
                    anchor: [0.75, 0.5],
                    rotateWithView: false,
                    rotation: -rotation + Math.PI ,
                    scale: .06
                  })
                }));
             }
        }
      });
    }
      return styles;
  })





  export default {
    name: "leakanalysis",
    components: {
      MyMap,
      MyMapGeo,
      MyMapPointer,
      MyMapLayers,
      MyMapPlacement,
      MyMapScale,
      MyMapOverview,
      MyMapZoom,
      MyMapScatter,
      MyMapLink,
      MyMapPopup,
      MyMapMarker,
      MyMapHtml,
      MyMapDrawer
    },
    data (){
      return {
        treeData:[
          {
            name: '宁夏',
            key: '0-0',
            child: [
              {
                name: '中卫',
                key: '0-0-0',
                disabled: true,
                child: [
                  { name: 'leaf', key: '0-0-0-0', disableCheckbox: true },
                  { name: 'leaf', key: '0-0-0-1' },
                ],
              },
              {
                name: 'parent 1-1',
                key: '0-0-1',
                child: [{ key: '0-0-1-0', name: 'zcvc' }],
              },
            ],
          },
        ],
        replaceFields: {
          children: 'child',
          title: 'name',
        },
        adapter: ({LayerGroup})=>{
          let settings=['vec_c', 'vec_w', 'cva_w', 'ibo_w']
          return new LayerGroup({
            layers: settings.map(n =>
                this.createTdtLayer(n)
            )
          })
        },
        layers: [
          {
            title: '交通图',
            adapter: ({LayerGroup})=>{
              let settings=['vec_c', 'vec_w', 'cva_w', 'ibo_w']
              return new LayerGroup({
                layers: settings.map(n =>
                    this.createTdtLayer(n)
                )
              })
            },
            preview: TDT
          },
          {
            title: '影像图',
            adapter: ({LayerGroup})=>{
              let settings=['img_w', 'cia_w']
              return new LayerGroup({
                layers: settings.map(n =>
                    this.createTdtLayer(n)
                )
              })
            },
            preview: Image
          },
          {
            title: '地形图',
            adapter: ({LayerGroup})=>{
              let settings=['ter_w', 'cta_w']
              return new LayerGroup({
                layers: settings.map(n =>
                    this.createTdtLayer(n)
                )
              })
            },
            preview: Terrain
          }
        ],
        geo: henan,
        showArea: 'b',
        loading:true,
        ripple1: {
            color: 'red', // 默认与 fill 参数一致
            period: 2, //  动画周期，秒数
            scale: 5, // 动画中波纹的最大缩放比
            type: 'fill' // 渲染类型 fill 或 stroke
        },
        ripple2: {
            period: 1, //  动画周期，秒数
            scale: 8, // 动画中波纹的最大缩放比
            strokeColor: 'red',
            strokeWidth: 5, // 边框宽度
            type: 'stroke' // 渲染类型 fill 或 stroke
        },
        monitorList: [],
        markers: null,
        zuitaimarkers: null,
        pin,
        markerPic,
        arrowPng,
        pressureData:[],
        waterData:[],
        showData:'a',
        ifShow:true,
        form: {
          end: "2020-01-23T15:53:59.358Z",
          start: "2021-08-14T15:53:59.358Z",
          wfid: 1
        },
        rangeH: [moment().subtract(5,'hours'),moment()],
        tree: [],
        cascaderDefault: [],
        factorys: [
          {
            value: "1",
            label: "盘锦市",
            children: [
              {
                value: "1-1",
                label: "大洼区",
                children: [
                  {
                    value: "1-1-1",
                    label: "陈家镇",
                    children: [
                      {
                        value: "1-1-1-1",
                        label: "龙水湾厂"
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ],
        region: {},
        showGeoInfo:false,
        leakData:[],
        geoShow:false,
        leakListData:[],
        positioning:'top-left',
        colorData:["rgba(44,123,182,0.8)","rgba(171,217,233,0.9)","rgba(255,255,191,0.9)","rgba(253,174,97,0.8)","rgba(215,25,28,0.8)"],
        colorFontData:["#fff","#2A7991","#2A7991","#fff","#fff"]
      }
    },
    created(){
      let param = getAreaParam();
      waterfactoryTree(param).then(r=>{
        this.tree = [r.data]
        this.cascaderDefault = this.getTreeDefaultValue(this.tree)
        console.log(this.cascaderDefault)
      })
      monitorAll().then(r=>{
        console.log(r)
        let monitorList = r.data
        let data1 = monitorList.filter(item => item.zutaiid ==null)
        let data2 = monitorList.filter(item => item.zutaiid !=null)

        this.markers =this.createMarkers(data1)
        this.zuitaimarkers =this.createMarkers(data2)
      })
      findByWfid(1,1).then(r=>{
        let waterData = r.data
        console.log(waterData)
        this.waterData =this.createWaterData(waterData)
        console.log(this.createWaterData(waterData))
      })
      findByWfid(1,3).then(r=>{
        let pressureData = r.data
        this.pressureData =this.createPerssureData(pressureData)
      })
      this.getleakData()
    },
    mounted(){

    },
    methods:{
      convert(obj){
        return fromLonLat(obj)
      },
      onSelect(selectedKeys, info) {
        console.log('selected', selectedKeys, info);
      },
      onCheck(checkedKeys, info) {
        console.log('onCheck', checkedKeys, info);
      },
      createTdtLayer(t = 'vec_c') {
        return new TileLayer({
          source: new XYZ({
            url: 'http://t{0-7}.tianditu.gov.cn/DataServer?T=' + t + '&x={x}&y={y}&l={z}&tk=8991986746152eb942b797bfe6b2e1f9'
          })
        })
      },
      showAreaChange(val){
        console.log('-----------------------------'+val)
        this.loading=false
        if(val.target.value=='a'){
          this.$nextTick(() => {
            /*
            const map = this.$refs.myMap.map
            const layer = this.$refs.myMap.getLayer()
            if (layer) {
              map.removeLayer(layer)
            }
            if (this.adapter) {
              this.adapter.__MY_LAYER__ = true
              map.addLayer(this.adapter)
            }*/

            this.adapter=({LayerGroup})=>{
              let settings=['img_w', 'cia_w']
              return new LayerGroup({
                layers: settings.map(n =>
                    this.createTdtLayer(n)
                )
              })
            }

            this.layers= [
              {
                title: '影像图',
                adapter: ({LayerGroup})=>{
                  let settings=['img_w', 'cia_w']
                  return new LayerGroup({
                    layers: settings.map(n =>
                        this.createTdtLayer(n)
                    )
                  })
                },
                preview: Image
              },
              {
                title: '交通图',
                adapter: ({LayerGroup})=>{
                  let settings=['vec_c', 'vec_w', 'cva_w', 'ibo_w']
                  return new LayerGroup({
                    layers: settings.map(n =>
                        this.createTdtLayer(n)
                    )
                  })
                },
                preview: TDT
              },
              {
                title: '地形图',
                adapter: ({LayerGroup})=>{
                  let settings=['ter_w', 'cta_w']
                  return new LayerGroup({
                    layers: settings.map(n =>
                        this.createTdtLayer(n)
                    )
                  })
                },
                preview: Terrain
              }
            ]
            this.loading=true
          })
        }else{
          this.$nextTick(() => {
            this.adapter={
              type: 'XYZ',
              // url:'http://t{0-7}.tianditu.gov.cn/DataServer?T=vec_w&x={x}&y={y}&l={z}&tk=8991986746152eb942b797bfe6b2e1f9'
              url: 'https://t' + Math.round(Math.random() * 7) + '.tianditu.gov.cn/DataServer?T=vec_w&x={x}&y={y}&l={z}&tk=8991986746152eb942b797bfe6b2e1f9'
            }

            this.layers= [
              {
                title: '影像图',
                adapter: {
                  type: 'XYZ',
                  // url:'http://t{0-7}.tianditu.gov.cn/DataServer?T=img_w&x={x}&y={y}&l={z}&tk=8991986746152eb942b797bfe6b2e1f9'
                  url: 'http://webst0{1-4}.is.autonavi.com/appmaptile?style=6&x={x}&y={y}&z={z}'
                },
                preview: Image
              },
              {
                title: '交通图',
                adapter: {
                  type: 'XYZ',
                  // url:'http://t{0-7}.tianditu.gov.cn/DataServer?T=vec_w&x={x}&y={y}&l={z}&tk=8991986746152eb942b797bfe6b2e1f9'
                  url: 'https://t' + Math.round(Math.random() * 7) + '.tianditu.gov.cn/DataServer?T=vec_w&x={x}&y={y}&l={z}&tk=8991986746152eb942b797bfe6b2e1f9'
                },
                preview: TDT
              },
              {
                title: '地形图',
                adapter: {
                  type: 'XYZ',
                  // url:'http://t{0-7}.tianditu.gov.cn/DataServer?T=ter_w&x={x}&y={y}&l={z}&tk=8991986746152eb942b797bfe6b2e1f9'
                  url: 'https://t' + Math.round(Math.random() * 7) + '.tianditu.gov.cn/DataServer?T=ter_w&x={x}&y={y}&l={z}&tk=8991986746152eb942b797bfe6b2e1f9'
                },
                preview: Terrain
              }
            ]
            this.loading=true
          })
        }
      },
      showDataChange(val){
        if(val.target.value=='a'){
          this.ifShow=true
        }else{
          this.ifShow=false
        }
      },
      createMarkers(data) {
        const markers = []
        for (let i = 0; i < data.length; i++) {
          if(data[i].id<=5){
            markers.push({
              coordinate: this.convert([data[i].lon,data[i].lat]),
              dataName: data[i].name,
            })
          }
        }
        return markers
      },
      createWaterData(data) {
        const markers = []
        for (let i = 0; i < data.length; i++) {
          if(data[i].id<=5){
            let deviceDetails=data[i].deviceDetails
            if(deviceDetails.length>0){
              markers.push({
                coordinate: this.convert([data[i].lon,data[i].lat]),
                dataName: deviceDetails.map(item => item.name+':'+item.ls+'m³/s').join("<br/>") ,
                id: i,
                datastatus:deviceDetails[0].datastatus
              })
            }
          }
        }
        return markers
      },
      createPerssureData(data) {
        const markers = []
        for (let i = 0; i < data.length; i++) {
          if(data[i].id<=5){
            let deviceDetails=data[i].deviceDetails
            if(deviceDetails.length>0){
              markers.push({
                coordinate: this.convert([data[i].lon,data[i].lat]),
                dataName: deviceDetails.map(item => item.name+':'+item.pressure+'Mpa').join("<br/>") ,
                id: i,
                datastatus:deviceDetails[0].datastatus
              })
            }
          }
        }
        return markers
      },
      geoReadyHandle(geo) {
        const features = geo.getFeatures()
        this.leakListData=[]
        features.forEach((feature) => {
          const featureData = feature.getProperties()
          const ls = featureData.ls
          if(ls){
            let newData = this.leakData.filter(item => item.fromid==featureData.MidFrom&&item.toid==featureData.MidTo)
            console.log('-------------'+JSON.stringify(featureData))
            if(newData.length>0){
              let color=Math.floor(newData[0].leakPercent/5)
              this.leakListData.push({
                coordinate: this.convert([featureData.centerlon,featureData.centerlat]),
                dataName: '漏损量：'+newData[0].leak+'<br/>漏损比：'+newData[0].leakPercent+'%',
                id: newData[0].id,
                color: color>4?4:color,
              })
              feature.set('color',this.colorData[color>4?4:color],true);
            }

            feature.setStyle(arrowStyles)
            /*
            if(newData.length>0){
              const styleObj = setStyle(`rgba(225, ${225 - value}, 0, 0.9)`,'漏损量:'+newData[0].leak+'；漏损比：'+newData[0].leakPercent+'%')
              feature.setStyle(styleObj)
            }else{
              const styleObj = setStyle(`rgba(225, ${225 - value}, 0, 0.9)`,'')
              feature.setStyle(styleObj)
            }*/
          }
        })
      },
      getTreeDefaultValue(tree){
        let arr = []
        let first = tree[0]
        arr.push(first.id)
        while (first.children && first.children.length > 0){
          arr.push(first.children[0].id)
          first = first.children[0]
        }
        return arr
      },
      onCascaderChange(value){
        console.log('onCascaderChange',value)
      },
      getleakData(){
        this.form.start = moment(this.rangeH[0]).format("YYYY-MM-DD HH:00:00")
        this.form.end = moment(this.rangeH[1]).format("YYYY-MM-DD HH:00:00")
        leakAnalysis(this.form).then(r=>{
          let data = r.data
          console.log(data)
          this.leakData=data
          this.geoShow=true
        })
      },
      handleClick(e, feature) {
        this.showGeoInfo=false
        const ls = feature.get('ls')
        if(lsList.includes(ls)){
          const MidFrom = feature.get('MidFrom')
          const MidTo = feature.get('MidTo')
          let newData = this.leakData.filter(item => item.fromid==MidFrom&&item.toid==MidTo)

          this.region = {newData}
          this.showGeoInfo=true
        }else{
          this.showGeoInfo=false
        }
      },

      handleQuery () {
        this.geoShow=false
        this.getleakData()
      },
      resetQuery () {
        this.getleakData()
      },
    }
  };
</script>

<style scoped>

</style>
